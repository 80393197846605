import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M21.167 20.976c-.006 1.031-.693 1.202-1.094 1.211l-17.095.004c-.322 0-.578-.09-.76-.266-.261-.259-.395-.742-.395-1.392V9.6h19.35c0 4.09.006 9.93-.006 11.376zM1.823 5.454c0-.946.365-1.351 1.222-1.356h1.75v1.329a.912.912 0 001.823 0v-1.33h9.62v1.33c0 .5.407.904.912.904a.908.908 0 00.911-.904v-1.33h1.83c.443.015.76.128.96.346.285.304.328.797.322.952V7.79H1.823V5.454zm21.173.023c.006-.25.006-1.373-.784-2.24-.377-.411-1.07-.908-2.291-.948h-1.86V.904c0-.5-.407-.904-.911-.904a.907.907 0 00-.912.904V2.29h-9.62V.904A.91.91 0 005.706 0a.907.907 0 00-.911.904V2.29H3.039C1.568 2.297-.006 3.134 0 5.456c0 .15.006 14.158 0 15.073 0 1.162.31 2.062.93 2.676.529.52 1.233.795 2.042.795h.006l17.089-.004h.006c1.167 0 2.905-.8 2.917-3.009.018-2.231.006-14.887.006-15.51z"
      />
    </svg>
  );
}

export default SvgComponent;
