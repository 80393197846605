import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import Layout from '../components/Layout';
import AuthContext from '../contexts/auth';
import { Container, TitleContainer } from 'ui/containers';
import { TextareaField, Form } from 'components/Forms';
import { get } from 'lodash';
import { Paragraph, Buttons as RawButtons, Button } from 'ui';
import Avatar from 'components/Avatar';
import { Formik } from 'formik';
import IcDate from '../assets/images/icons/IcDate';
import { useTranslation } from 'react-i18next';

const Buttons = styled(RawButtons)`
  > * {
    margin-right: ${({ theme }) => theme.spacing()};
  }
`;

const NameWrapper = styled.div`
  font-weight: 700;
`;

const RoleWrapper = styled.div`
  color: ${({ theme }) => theme.textLighter};
  font-size: 14px;
  font-weight: 400;
`;

const TextAreaContainer = styled.div`
  max-width: 50em;
`;

const HotelOwner = styled.div`
  display: flex;
`;

const OwnerInfos = styled.div`
  margin-left: ${({ theme }) => theme.spacing()};
`;

const Pictures = styled.div``;

const Confirmation = ({ owner }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        {user && (
          <TitleContainer bold>{`${get(
            user,
            'firstName',
            '',
          )}, welcome back to...`}</TitleContainer>
        )}
        <Paragraph>
          {`Vous avez payé un total de 492€ pour 2 personnes, du 26/06 au 30/06. Passez un incroyable séjour!`}
        </Paragraph>
        <Paragraph>{t('templates.confirmation.num')}</Paragraph>
        <Buttons isLeftAlign>
          <Button variant="primary">
            <IcDate color="#fff" />
            {t('templates.confirmation.save')}
          </Button>
          <Button variant="secondary">
            {t('templates.confirmation.share')}
          </Button>
        </Buttons>

        <Formik>
          {() => (
            <Form>
              <HotelOwner>
                <Avatar
                  small
                  firstName={get(owner, 'firstName', '')}
                  lastName={get(owner, 'lastName', '')}
                />

                <OwnerInfos>
                  <NameWrapper>{get(owner, 'name', '')}</NameWrapper>
                  <RoleWrapper>{get(owner, 'role', '')}</RoleWrapper>
                </OwnerInfos>
              </HotelOwner>
              <TextAreaContainer>
                <TextareaField name="body" label="Message" minRows={4} />
              </TextAreaContainer>
              <Button variant="primary">Envoyer</Button>
            </Form>
          )}
        </Formik>
        <TitleContainer>{t('templates.confirmation.trip')}</TitleContainer>
        <p>195 – 199 Hang Bong street, Hoan Kiem district, Hanoi</p>
        <p>+84 24 3266 5555</p>
        <Pictures>{t('utils.img')}</Pictures>
        <TitleContainer>{t('templates.confirmation.forget')}</TitleContainer>
        <p>{t('templates.confirmation.boarding')}</p>
        <p>{t('templates.confirmation.pets')}</p>
        <p>{t('templates.confirmation.adapt')}</p>
        <Buttons isLeftAlign>
          <Button variant="primary" as={Link} to="/">
            {t('utils.home')}
          </Button>
          <Button variant="secondary" as={Link} to="/">
            {t('hotel.offer.other')}
          </Button>
        </Buttons>
      </Container>
    </Layout>
  );
};

export default Confirmation;
